import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import MapIcon from '@mui/icons-material/Map';
import IconButton from '@mui/material/IconButton';

import useIsSmall from 'hooks/useIsSmall';
import useCreatePath from 'hooks/useCreatePath';

import useTranslate from '../../hooks/useTranslate';

function Map() {
  const t = useTranslate();
  const isSmall = useIsSmall();
  const createPath = useCreatePath();

  if (isSmall) {
    return (
      <IconButton color="inherit" aria-label={t('map')} component={Link} to={createPath('map')}>
        <MapIcon />
      </IconButton>
    );
  }

  return (
    <Button
      variant="outlined"
      color="inherit"
      startIcon={<MapIcon />}
      component={Link}
      size="medium"
      to={createPath('map')}
    >
      {t('map')}
    </Button>
  );
}

export default Map;
