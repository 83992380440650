import { useState, MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';

import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MapIcon from '@mui/icons-material/Map';
import CalculateIcon from '@mui/icons-material/Calculate';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

import useUser from 'data/users/hooks/useUser';

import useCreatePath from 'hooks/useCreatePath';

import WhatsNewMenuItem from 'modules/WhatsNew/components/WhatsNewMenuItem';
import WhatsNewBadge from 'modules/WhatsNew/components/WhatsNewBadge';

import useTranslate from '../../hooks/useTranslate';

import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import UnitSwitcher from '../UnitSwitcher/UnitSwitcher';
import HelpSection from '../HelpSection/HelpSection';
import Location from '../Location/Location';

import UserMenu from './UserMenu';

interface AppMenuProps {
  isMobile: boolean;
}

function AppMenu({ isMobile }: AppMenuProps) {
  const t = useTranslate();
  const { requireAuth } = useUser();
  const createPath = useCreatePath();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl],
  );

  const onCloseMenu = useCallback(() => setAnchorEl(null), []);

  const isMenuOpen = !!anchorEl;

  const MenuItems = (
    <nav>
      <List>
        <WhatsNewMenuItem onClick={onCloseMenu} />
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={onCloseMenu} component={Link} to={createPath('')}>
          <ListItemIcon>
            <LocationCityIcon />
          </ListItemIcon>
          <ListItemText primary={t('my_locations')} />
        </ListItemButton>
        <ListItemButton onClick={onCloseMenu} component={Link} to={createPath('map/')}>
          <ListItemIcon>
            <MapIcon />
          </ListItemIcon>
          <ListItemText primary={t('map')} />
        </ListItemButton>
        <ListItemButton onClick={onCloseMenu} component={Link} to={createPath('compare/')}>
          <ListItemIcon>
            <ViewWeekIcon />
          </ListItemIcon>
          <ListItemText primary={t('compare')} />
        </ListItemButton>
        <Location onClick={onCloseMenu} />
        <ListItem>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <LanguageSwitcher />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CalculateIcon />
          </ListItemIcon>
          <UnitSwitcher />
        </ListItem>
      </List>
      <Divider />
      <List>
        <HelpSection onClick={onCloseMenu} />
      </List>
      <Divider />
      <UserMenu onCloseMenu={onCloseMenu} />
    </nav>
  );

  const AuthMenuItems = (
    <nav>
      <List>
        <ListItem>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <LanguageSwitcher />
        </ListItem>
      </List>
      <Divider />
      <UserMenu onCloseMenu={onCloseMenu} />
    </nav>
  );

  const MenuButton = (
    <WhatsNewBadge hide={isMenuOpen}>
      <IconButton onClick={handleClick} id="main-menu" size="large" aria-label={t('menu')}>
        <MenuIcon />
      </IconButton>
    </WhatsNewBadge>
  );

  const AuthMenuButton = (
    <IconButton onClick={handleClick} id="main-menu" size="large" aria-label={t('menu')}>
      <MenuIcon />
    </IconButton>
  );

  return (
    <>
      {requireAuth ? AuthMenuButton : MenuButton}
      {isMobile ? (
        <Drawer keepMounted anchor="right" open={isMenuOpen} onClose={onCloseMenu}>
          {requireAuth ? AuthMenuItems : MenuItems}
        </Drawer>
      ) : (
        <Menu
          id="main-menu-nav"
          open={isMenuOpen}
          anchorEl={anchorEl}
          keepMounted
          onClose={onCloseMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          {requireAuth ? AuthMenuItems : MenuItems}
        </Menu>
      )}
    </>
  );
}

export default AppMenu;
